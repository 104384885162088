// constants
import { FormAnalysisActionTypeConst } from './constants';

export interface FormAnalysisActionType<TPayload> {
    type: FormAnalysisActionTypeConst.CHANGE_DATASELECTION_EXPERIMENT
        | FormAnalysisActionTypeConst.CHANGE_DATASELECTION_SAMPLES
        | FormAnalysisActionTypeConst.CHANGE_DATASELECTION_SELECTION_EXPERIMENT
        | FormAnalysisActionTypeConst.CHANGE_FILTER1_MIN_CELL_PER_GENE
        | FormAnalysisActionTypeConst.CHANGE_FILTER1_MIN_GENE_PER_CELL
        | FormAnalysisActionTypeConst.CHANGE_FILTER2_DIFF_GENES_PER_CELL_MIN
        | FormAnalysisActionTypeConst.CHANGE_FILTER2_DIFF_GENES_PER_CELL_MAX
        | FormAnalysisActionTypeConst.CHANGE_FILTER2_MAX_MITO_PERCENT
        | FormAnalysisActionTypeConst.CHANGE_QC_FILTERS
        | FormAnalysisActionTypeConst.CHANGE_QC_FILTERS_DEFAULT
        | FormAnalysisActionTypeConst.CHANGE_QC_FILTERS_KEY

        | FormAnalysisActionTypeConst.CHANGE_GENES_NUMBER
        | FormAnalysisActionTypeConst.CHANGE_INTEGRATION_MERGE_ENABLE
        | FormAnalysisActionTypeConst.CHANGE_INTEGRATION_MERGE_METADATA


        | FormAnalysisActionTypeConst.CHANGE_CLUSTERING_LAST_SENT
        | FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION
        | FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION_LOUVAIN_RESO
        | FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION_PC_NUMBER
        | FormAnalysisActionTypeConst.CHANGE_CLUSTERING_TSNE_PERPLEXITY
        | FormAnalysisActionTypeConst.CHANGE_CLUSTERING_TSNE_EARLY_EXAGGERATION
        | FormAnalysisActionTypeConst.CHANGE_SUBCLUSTERING
        | FormAnalysisActionTypeConst.CHANGE_SUBCLUSTERING_SENT
        | FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_GENES_NAME_LIST
        | FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_RAW_COUNTS
        | FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SORTED_SCATTER
        | FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SCATTER_MARKER_SIZE
        | FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SCATTER_COLOR

        | FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_METADATAS
        | FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_METADATAS_VS
        | FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS
        | FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS
        | FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS_VS
        | FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_TYPE

        | FormAnalysisActionTypeConst.CHANGE_CLEAR_DIFF_GENES_EXPRESSION_2
        | FormAnalysisActionTypeConst.CHANGE_CLEAR_SUB_CLUSTER
        | FormAnalysisActionTypeConst.CHANGE_CLEAR_ALL
        | FormAnalysisActionTypeConst.CHANGE_LOAD_ALL_DATA
        | FormAnalysisActionTypeConst.CHANGE_USER_SAVES_DATA
        | FormAnalysisActionTypeConst.CHANGE_USER_DATA_MEMORY_USAGE
        | FormAnalysisActionTypeConst.CHANGE_USER_DATA_SAVED_ANALYSIS_MEMORY_USAGE
        | FormAnalysisActionTypeConst.CHANGE_USER_RIGHTS
        | FormAnalysisActionTypeConst.CHANGE_WEBSERVER_ADMIN_INFOS;
    payload?: TPayload;
}

/*
interface UserData {
    connection_connected: boolean;
    lastMessage: string;
}

    filter1_minCellPerGene: 3,
    filter1_minGenePerCell: 200,
    filter2_diffGenesPerCellMin: 150,
    filter2_diffGenesPerCellMax: 2800,
    filter2_maxMitoPercent: 5.0
*/

export const changeDataSelectionExperiment = (experiment: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DATASELECTION_EXPERIMENT,
    payload: experiment,
});

export const changeDataSelectionSamples = (samples: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DATASELECTION_SAMPLES,
    payload: samples,
});

export const changeDataSelectionSamplesExperiment = (experiment_name: string): FormAnalysisActionType<string> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DATASELECTION_SELECTION_EXPERIMENT,
    payload: experiment_name,
});

export const changeFilter1MinCellPerGene = (filter1_minCellPerGene: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_FILTER1_MIN_CELL_PER_GENE,
    payload: filter1_minCellPerGene,
});

export const changeFilter1MinGenePerCell = (filter1_minGenePerCell: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_FILTER1_MIN_GENE_PER_CELL,
    payload: filter1_minGenePerCell,
});

export const changeFilter2DiffGenesPerCellMin = (filter2_diffGenesPerCellMin: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_FILTER2_DIFF_GENES_PER_CELL_MIN,
    payload: filter2_diffGenesPerCellMin,
});

export const changeFilter2DiffGenesPerCellMax = (filter2_diffGenesPerCellMax: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_FILTER2_DIFF_GENES_PER_CELL_MAX,
    payload: filter2_diffGenesPerCellMax,
});

export const changeFilter2MaxMitoPercent = (filter2_maxMitoPercent: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_FILTER2_MAX_MITO_PERCENT,
    payload: filter2_maxMitoPercent,
});

export const changeGenesNumber = (genes_number_used: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_GENES_NUMBER,
    payload: genes_number_used,
});

export const changeIntegrationMergeEnable = (isEnable: boolean): FormAnalysisActionType<boolean> => ({
    type: FormAnalysisActionTypeConst.CHANGE_INTEGRATION_MERGE_ENABLE,
    payload: isEnable,
});

export const changeIntegrationMergeMetadata = (metadata: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_INTEGRATION_MERGE_METADATA,
    payload: metadata,
});

export const changeClusteringLastSent = (projection: string, louvain_resolution:number, pc_number:number, tsne_perplexity:number, tsne_early_exaggeration:number): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLUSTERING_LAST_SENT,
    payload: {projection, louvain_resolution, pc_number, tsne_perplexity, tsne_early_exaggeration},
});

export const changeSubClusters = (vSubClusters: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_SUBCLUSTERING,
    payload: vSubClusters,
});

export const changeSubClustersSent = (vSubClusters: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_SUBCLUSTERING_SENT,
    payload: vSubClusters,
});

export const changeClusteringProjection = (projection: string): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION,
    payload: projection,
});

export const changeClusteringProjectionLouvainReso = (louvain_reso: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION_LOUVAIN_RESO,
    payload: louvain_reso,
});

export const changeClusteringProjectionPcNumber = (pc_number: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION_PC_NUMBER,
    payload: pc_number,
});

export const changeClusteringTsnePerplexity = (perplexity: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLUSTERING_TSNE_PERPLEXITY,
    payload: perplexity,
});

export const changeClusteringTsneEarlyExageration = (early_exageration: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLUSTERING_TSNE_EARLY_EXAGGERATION,
    payload: early_exageration,
});

export const changeGenesExpressionGenesNameList = (geneExpression_genesNameList: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_GENES_NAME_LIST,
    payload: geneExpression_genesNameList,
});

export const changeGenesExpressionRawCounts = (geneExpression_rawCounts: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_RAW_COUNTS,
    payload: geneExpression_rawCounts,
});

export const changeGenesExpressionSortedScatter= (geneExpression_sortedScatter: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SORTED_SCATTER,
    payload: geneExpression_sortedScatter,
});

export const changeGenesExpressioScatterColor= (geneExpression_scatter_color: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SCATTER_COLOR,
    payload: geneExpression_scatter_color,
});

export const changeGenesExpressionScatterMarkerSize= (geneExpression_scatter_marker_size: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SCATTER_MARKER_SIZE,
    payload: geneExpression_scatter_marker_size,
});

export const changeDiffGenesExpressionMetadatas= (metadatas_list: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_METADATAS,
    payload: metadatas_list,
});

export const changeDiffGenesExpressionMetadatasVs= (metadatas_list: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_METADATAS_VS,
    payload: metadatas_list,
});

export const changeDiffGenesExpressionClusters= (clusters_list: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS,
    payload: clusters_list,
});

export const changeDiffGenesExpressionClustersClusters= (clusters_list: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS,
    payload: clusters_list,
});

export const changeDiffGenesExpressionClustersClustersVs= (clusters_list: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS_VS,
    payload: clusters_list,
});

export const changeDiffGenesExpressionType= (type: string): FormAnalysisActionType<string> => ({
    type: FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_TYPE,
    payload: type,
});

export const changeQcFilter= (qc_filter: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_QC_FILTERS,
    payload: qc_filter
});

export const changeDefaultQcFilter= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_QC_FILTERS_DEFAULT,
    payload: data
});

export const changeQcFilterKey= (data: number): FormAnalysisActionType<number> => ({
    type: FormAnalysisActionTypeConst.CHANGE_QC_FILTERS_KEY,
    payload: data
});

export const clearDiffGenesExpression2= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLEAR_DIFF_GENES_EXPRESSION_2,
    payload: data
});

export const clearSubClusters= (): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLEAR_SUB_CLUSTER,
    payload: undefined
});

export const clearAllFormAnalysis= (): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_CLEAR_ALL,
    payload: undefined
});

export const updateAllFormAnalysisData= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_LOAD_ALL_DATA,
    payload: data
});

export const updateUserSavesList= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_USER_SAVES_DATA,
    payload: data
});

export const updateUserDataMemoryUsage= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_USER_DATA_MEMORY_USAGE,
    payload: data
});

export const updateUserSavedAnalysisMemoryUsage= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_USER_DATA_SAVED_ANALYSIS_MEMORY_USAGE,
    payload: data
});

export const updateUserRights= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_USER_RIGHTS,
    payload: data
});

export const updateWebServerAdminInfos= (data: any): FormAnalysisActionType<any> => ({
    type: FormAnalysisActionTypeConst.CHANGE_WEBSERVER_ADMIN_INFOS,
    payload: data
});
