import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    const baseUrl = '/login/';

    var formData = new FormData();
    formData.append("password", params.password)
    formData.append("email", params.email)    

    return api.create(`${baseUrl}`, formData);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: {  email: string; password: string, validation_key:string }) {
    const baseUrl = '/register/';
    console.log("auth.ts signup email:", params.email, "password:", params.password, "validation_key:", params.validation_key, "len=",params.validation_key.length)

    var formData = new FormData();
    formData.append("password", params.password)
    formData.append("email", params.email)
    formData.append("validation_key", params.validation_key)
    
    return api.create(`${baseUrl}`, formData);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';

    var formData = new FormData();
    formData.append("email", params.email)
    console.log("forgotPassword params:",params)

    return api.create(`${baseUrl}`, formData);
}

function changePassword(params: { password_old: string, password_new: string, user_token_server: string }) {
    const baseUrl = '/change-password/';

    var formData = new FormData();
    formData.append("password_old", params.password_old)
    formData.append("password_new", params.password_new)
    formData.append("user_token_server", params.user_token_server)
    
    console.log("changePassword params:",params)

    return api.create(`${baseUrl}`, formData);
}

export { login, logout, signup, forgotPassword, changePassword };
