import { Toast } from 'react-bootstrap';
import { useRedux } from '../../hooks';

import{
    changeNotification
} from '../../redux/actions';

const NotificationGlobal = () => {

    const { dispatch, appSelector } = useRedux();

    const {
        notifications,
    } = appSelector((state) => ({
        notifications:         state.Params.notifications
    }));

    //console.log("NotificationGlobal notifications:", notifications)


    const onShow = (index) =>
    {
        console.log("NotificationGlobal onShow ", index)
    }

    const onClose = (index, item) =>
    {
        console.log("NotificationGlobal onClose ", index, item)

        console.log("NotificationGlobal notifications ", notifications)

        var tmp_notifications = JSON.parse(JSON.stringify(notifications))

        var id_delete = notifications.indexOf(item)
        console.log("NotificationGlobal id_delete: ", id_delete)


        if (id_delete > -1)
        {
            let vErrorsId = []

            if (notifications[id_delete].type === "error")
            {
                vErrorsId.push(id_delete)
            }

            let bIsError = false
            
            for(let i = 0; i< notifications.length ; i++)
            {
                let notification = notifications[i]
                // console.log("NotificationGlobal ",i, notification.type, notification.type === "error" )
                if (notification.type !== "error")
                {
                    vErrorsId.push(i)
                }
            }

            console.log("NotificationGlobal tmp_notifications before:", tmp_notifications, " bIsError:", bIsError, " id_delete:", id_delete, "vErrorsId:",vErrorsId)
            
            for(let i = 0; i<vErrorsId.length ; i++)
            {
                tmp_notifications.splice(vErrorsId[i], 1)
            }
            
            
            console.log("NotificationGlobal tmp_notifications after:", tmp_notifications)
            dispatch(changeNotification(tmp_notifications))
                
        }
        //tmp_notifications.

    }


    return(
        <>
        <div className="position-fixed bottom-0 end-0 p-3" style={{"zIndex": "11"}} >

            {(notifications || []).map((notification, index) => {

                var color = "bg-successd-flex align-items-center mt-4 "
                switch(notification.type)
                {
                    case "error":
                        color="bg-danger"
                        break;
                    case "warning":
                        color="bg-warning"
                        break;
                    case "success":
                        color="bg-success"
                        break;
                    case "info":
                        color="bg-info"
                        break;
                    default:
                        color="bg-info" 
                }

                return (
                <Toast key={index}
                    className={color}
                    show={onShow(notification.id)}
                    onClose={() => onClose(notification.id, notification)}
                    delay={notification.delay}
                    autohide={(notification.type==="error" || notification.type==="warning") ? false : true}
                >   
                    <Toast.Header ><strong className="me-auto">{notification.type}</strong><small className="float-right">Close</small></Toast.Header>
                    <Toast.Body><h4 className="header-title">{notification.description}</h4></Toast.Body>
                </Toast>
                );
            })}

        </div>
        </>
    )


}


export default NotificationGlobal

/*
bg-warning
bg-success







    <div className="position-fixed bottom-0 end-0 p-3" style={{"zIndex": "11"}} >
            <Toast
                className="d-flex align-items-center mt-4"
                show={showTest}
                onClose={() => closeTest()}
                delay={6000}
                autohide
            >
                <Toast.Body>Hello, world! This is a toast message.</Toast.Body>

            </Toast>

            <Toast
                className="d-flex align-items-center mt-4"
                show={showTest}
                onClose={() => closeTest()}
                delay={6000}
                autohide
            >
                <Toast.Body>Heaaaaaaaaaaaaaaaamessage.</Toast.Body>

            </Toast>
        </div>

*/