// constants
import { ProgressActionTypeConst } from './constants';

export interface ProgressActionType<TPayload> {
    type: ProgressActionTypeConst.CHANGE_IS_COMPLETED
        | ProgressActionTypeConst.CHANGE_PROGRESS
        | ProgressActionTypeConst.CHANGE_IS_LOADING
        | ProgressActionTypeConst.CHANGE_CANCEL_CURRENT_WORK
        | ProgressActionTypeConst.CHANGE_CLEAR
        | ProgressActionTypeConst.CHANGE_BACKEND_STATS
        | ProgressActionTypeConst.CHANGE_BACKEND_ALL_USERS
        | ProgressActionTypeConst.CHANGE_LOAD_ALL_DATA
        | ProgressActionTypeConst.CHANGE_PROGRESS_SAVE_LOAD_BACKEND;
    payload?: TPayload;
}


export const updateIsCompleted = (stepName:string, data:boolean): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_IS_COMPLETED,
    payload: {stepName, data},
});

export const updateProgress = (stepName:string, data:number): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_PROGRESS,
    payload: {stepName, data},
});

export const updateIsLoading = (stepName:string, data:boolean): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_IS_LOADING,
    payload: {stepName, data},
});

export const cancelCurrentTask = (): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_CANCEL_CURRENT_WORK,
    payload: {},
});

export const clearProgress = (): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_CLEAR,
    payload: {},
});

export const setBackendStats = (data:any): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_BACKEND_STATS,
    payload: data,
});

export const setBackendAllUsers = (data:any): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_BACKEND_ALL_USERS,
    payload: data,
});

export const updateAllProgressData = (data:any): ProgressActionType<any> => ({
    type: ProgressActionTypeConst.CHANGE_LOAD_ALL_DATA,
    payload: data,
});

export const updateProgressBackend = (data:number): ProgressActionType<number> => ({
    type: ProgressActionTypeConst.CHANGE_PROGRESS_SAVE_LOAD_BACKEND,
    payload: data,
});