// action constants
import { WebsocketActionTypes } from './constants';

/*
// app constants
import {
    LayoutTypes,
    LayoutWidth,
    MenuPositions,
    SideBarTheme,
    SideBarTypes,
    TopbarTheme,
} from '../../constants/layout';
*/

// actions
import { WebsocketActionType } from './actions';


interface WebsocketData {
    connection_connected: boolean;
    lastMessage : string;
    ask_user_data: boolean;
    experiments_tree: any;
    currentSamplesExp: any;
    currentSamplesOnlyWithoutSpaces: any;
    qc_violinRawCounts:any;
    qc_violinGenesNbPerCell:any;
    qc_violinMitoPercent:any;
    qc_cells_number_after_filters: any;
    qc_genes_number_after_filters: any;
    qc_scatter_mt_pecentage_counts:any;
    qc_scatter_gene_nb_counts:any;
    samplesName:any;
    qc_complexity_complexity:any;
    qc_complexity_counts:any;
    qc_complexity_features:any;
    qc_complexity_percentMt:any;
    pca_elbow:any;
    pca_heatmaps:any;

    cluster_projection_type:string;
    cluster_scatter_2d:any
    cluster_list:any // [{id_cluster, isSubCluster}, ...]
    cluster_sample_filter:any // [sample_name][cluster_id][list of indices]
    cluster_markers:any;
    cluster_markers_csv:any;
    cluster_scatter_3d:any

    highlyFeatureGenes:any;
    featureGenesTable:any;

    genesExpression_genesName:any;
    geneExpression_violin_genes:any;
    geneExpression_scatter_genes:any;
    geneExpression_rawCountsType:string;
    geneExpression_table:any;
    geneExpression_table_csv:any;
    geneExpression_table_from_cluster:any;

    test_graph:any;
    h5_treeview:any;
    websocketAck:number;
    websocketSendMessageAck:number;
    webscoketForceReload:number;
    forceReloadAfterAnalysis:number;
    
}

// utils
//import { getLayoutConfigs } from '../../utils';

const INIT_STATE = {
    connection_connected: false,
    lastMessage: "",
    ask_user_data: false,
    experiments_tree: {},
    currentSamplesExp: {}, // [exp1, sample1, exp1, sample2, ...]
    currentSamplesOnlyWithoutSpaces: [],
    qc_violinRawCounts:{},
    qc_violinGenesNbPerCell:{},
    qc_violinMitoPercent:{},

    qc_cells_number_after_filters:{}, //[sampleName][umi/NFeatures/mito/combination/before].nb_barcodes
    qc_genes_number_after_filters:{}, //[sampleName][before_filter, after_filter].nb_genes
    
    qc_scatter_mt_pecentage_counts:{},
    qc_scatter_gene_nb_counts:{},

    samplesName:{},
    qc_complexity_complexity:{},
    qc_complexity_counts:{},
    qc_complexity_features:{},
    qc_complexity_percentMt:{},
    highlyFeatureGenes:{},
    featureGenesTable:[],
    pca_elbow:{},
    pca_heatmaps:[],

    cluster_projection_type:"", // UMAP/TSNE
    cluster_scatter_2d:[],
    cluster_list:[],
    cluster_sample_filter:{},
    cluster_markers:{},
    cluster_markers_csv:[],
    cluster_scatter_3d:[],

    genesExpression_genesName:[],
    geneExpression_violin_genes:{},
    geneExpression_scatter_genes:{},
    geneExpression_rawCountsType:"",
    geneExpression_table:{},
    geneExpression_table_csv:[],
    geneExpression_table_from_cluster:[],

    test_graph:{},
    h5_treeview:{},
    websocketAck:0,
    websocketSendMessageAck:0,
    webscoketForceReload:0,
    forceReloadAfterAnalysis:0
};

const WebSocket = (state: WebsocketData = INIT_STATE, action: WebsocketActionType<string | boolean | any | null>) => {
    switch (action.type) {
        case WebsocketActionTypes.CHANGE_CONNECTION_STATUS:
            return {
                ...state,
                connection_connected: action.payload,
            };
        case WebsocketActionTypes.CHANGE_LAST_MESSAGE:
            return {
                ...state,
                lastMessage: action.payload
            };

        case WebsocketActionTypes.CHANGE_ASK_USER_DATA:
            return {
                ...state,
                ask_user_data: action.payload
            };

        case WebsocketActionTypes.CHANGE_DATA:
            return {
                ...state,
                experiments_tree: action.payload
            };
        
        case WebsocketActionTypes.CHANGE_CURRENT_SAMPLE_EXP:
            return {
                ...state,
                currentSamplesExp: action.payload,
            };
        
        case WebsocketActionTypes.CHANGE_CURRENT_SAMPLE_ONLY:
            return {
                ...state,
                currentSamplesOnlyWithoutSpaces: action.payload,
            };   

        case WebsocketActionTypes.CHANGE_QC_VIOLIN_RAW_COUNTS:
            return {
                ...state,
                qc_violinRawCounts:{
                    ...state.qc_violinRawCounts,  [action.payload.sampleName] :action.payload.data
                }                
            };
        
        case WebsocketActionTypes.CHANGE_QC_VIOLIN_GENES_NB_PER_CELL:
            return {
                ...state,
                qc_violinGenesNbPerCell:{
                    ...state.qc_violinGenesNbPerCell,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_QC_VIOLIN_MITO_PERCENT:
            return {
                ...state,
                qc_violinMitoPercent:{
                    ...state.qc_violinMitoPercent,  [action.payload.sampleName] :action.payload.data
                }
            };

        case WebsocketActionTypes.CHANGE_QC_CELLS_NUMBER_AFTER_FILTERS:
            return {
                ...state,
                qc_cells_number_after_filters:action.payload
            };

        case WebsocketActionTypes.CHANGE_QC_GENES_NUMBER_AFTER_FILTERS:
            return {
                ...state,
                qc_genes_number_after_filters:action.payload
            };
            
        case WebsocketActionTypes.CHANGE_QC_SCATTER_MTPERCENT_COUNTS:
            return {
                ...state,
                qc_scatter_mt_pecentage_counts:{
                    ...state.qc_scatter_mt_pecentage_counts,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_QC_SCATTER_GENENB_COUNTS:
            return {
                ...state,
                qc_scatter_gene_nb_counts:{
                    ...state.qc_scatter_gene_nb_counts,  [action.payload.sampleName] :action.payload.data
                }                
            };            

        case WebsocketActionTypes.CHANGE_SAMPLES_NAME:
            return {
                ...state,
                samplesName:{
                    ...state.samplesName,  [action.payload] :true
                }
                
            };

        case WebsocketActionTypes.CHANGE_QC_COMPLEXITY_COMPLEXITY:
            return {
                ...state,
                qc_complexity_complexity:{
                    ...state.qc_complexity_complexity,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_QC_COMPLEXITY_COUNTS:
            return {
                ...state,
                qc_complexity_counts:{
                    ...state.qc_complexity_counts,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_QC_COMPLEXITY_FEATURES:
            return {
                ...state,
                qc_complexity_features:{
                    ...state.qc_complexity_features,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_QC_COMPLEXITY_PERCENTMT:
            return {
                ...state,
                qc_complexity_percentMt:{
                    ...state.qc_complexity_percentMt,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_HIGHLY_FEATURE_GENES:
            return {
                ...state,
                highlyFeatureGenes: action.payload
            };

        case WebsocketActionTypes.CHANGE_FEATURE_GENES_TABLE:
            return {
                ...state,
                featureGenesTable: action.payload
            };                       

        case WebsocketActionTypes.CHANGE_TEST_GRAPH:
            return {
                ...state,
                test_graph:{
                    ...state.test_graph,  [action.payload.sampleName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_PCA_ELBOW:
            return {
                ...state,
                pca_elbow: action.payload
            };        
        
        case WebsocketActionTypes.CHANGE_PCA_HEATMAPS:
            return {
                ...state,
                pca_heatmaps: action.payload
            };    

        case WebsocketActionTypes.CHANGE_CLUSTER_LIST:
            return {
                ...state,
                cluster_list: action.payload
            };    

        case WebsocketActionTypes.CHANGE_CLUSTER_SAMPLE_FILTER:
            return {
                ...state,
                cluster_sample_filter: action.payload
            };     

        case WebsocketActionTypes.CHANGE_CLUSTER_PROJETION_TYPE:
            return {
                ...state,
                cluster_projection_type: action.payload
            };    

        case WebsocketActionTypes.CHANGE_CLUSTER_SCATTER_2D:
            return {
                ...state,
                cluster_scatter_2d: action.payload
            };    

        case WebsocketActionTypes.CHANGE_CLUSTER_MARKERS:
            return {
                ...state,
                cluster_markers:{
                    ...state.cluster_markers,  [action.payload.clusterName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_CLUSTER_MARKERS_CSV:
            return{ 
                ...state,
                cluster_markers_csv: state.cluster_markers_csv.concat(action.payload)
            };

        case WebsocketActionTypes.CHANGE_CLUSTER_MARKERS_CSV_FULL_COPY:
            return{ 
                ...state,
                cluster_markers_csv: action.payload
            };
    
        case WebsocketActionTypes.CHANGE_CLUSTER_SCATTER_3D:
            return {
                ...state,
                cluster_scatter_3d: action.payload
            };    

        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_GENES_NAME:
            return {
                ...state,
                genesExpression_genesName: action.payload
            };    
        
        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_VIOLIN_GENES:
            return {
                ...state,
                geneExpression_violin_genes:{
                    ...state.geneExpression_violin_genes,  [action.payload.geneName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_SCATTER_GENES:
            return {
                ...state,
                geneExpression_scatter_genes:{
                    ...state.geneExpression_scatter_genes,  [action.payload.geneName] :action.payload.data
                }
            };

        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE:
            return {
                ...state,
                geneExpression_table:{
                    ...state.geneExpression_table,  [action.payload.clusterName] :action.payload.data
                }                
            };

        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE_CSV:
            return{ 
                ...state,
                geneExpression_table_csv: state.geneExpression_table_csv.concat(action.payload)
            };         
            
        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE_FROM_CLUSTER:
            return {
                ...state,
                geneExpression_table_from_cluster: action.payload
            };        
            

        case WebsocketActionTypes.CHANGE_CLEAR_ALL:
            console.log("clear all graph")
            
            return {
                ...state,
                qc_violinRawCounts:INIT_STATE.qc_violinRawCounts,
                qc_violinGenesNbPerCell:INIT_STATE.qc_violinGenesNbPerCell,
                qc_violinMitoPercent:INIT_STATE.qc_violinMitoPercent,
              
                qc_scatter_mt_pecentage_counts:INIT_STATE.qc_scatter_mt_pecentage_counts,
                qc_scatter_gene_nb_counts:INIT_STATE.qc_scatter_gene_nb_counts,
                qc_cells_number_after_filters: INIT_STATE.qc_cells_number_after_filters,
                qc_genes_number_after_filters: INIT_STATE.qc_genes_number_after_filters,

                samplesName:INIT_STATE.samplesName,
                qc_complexity_complexity:INIT_STATE.qc_complexity_complexity,
                qc_complexity_counts:INIT_STATE.qc_complexity_counts,
                qc_complexity_features:INIT_STATE.qc_complexity_features,
                qc_complexity_percentMt:INIT_STATE.qc_complexity_percentMt,

                highlyFeatureGenes: {},//INIT_STATE.highlyFeatureGenes,
                featureGenesTable:[],//INIT_STATE.featureGenesTable,
                pca_elbow:INIT_STATE.pca_elbow,
                pca_heatmaps:INIT_STATE.pca_heatmaps,            

                cluster_projection_type:INIT_STATE.cluster_projection_type, // UMAP/TSNE
                cluster_scatter_2d:INIT_STATE.cluster_scatter_2d,
                cluster_markers:INIT_STATE.cluster_markers,
                cluster_markers_csv:INIT_STATE.cluster_markers_csv,
                cluster_scatter_3d:INIT_STATE.cluster_scatter_3d,
          
                genesExpression_genesName:INIT_STATE.genesExpression_genesName,
                geneExpression_violin_genes:INIT_STATE.geneExpression_violin_genes,
                geneExpression_scatter_genes:INIT_STATE.geneExpression_scatter_genes,
                geneExpression_rawCountsType:INIT_STATE.geneExpression_rawCountsType,
                geneExpression_table:INIT_STATE.geneExpression_table,
                geneExpression_table_csv:INIT_STATE.geneExpression_table_csv,
                geneExpression_table_from_cluster:INIT_STATE.geneExpression_table_from_cluster,
                cluster_list:[],

                cluster_sample_filter:{},
            };

            
        case WebsocketActionTypes.CHANGE_CLEAR_DE_INTEGRATION:
            return {
                ...state,
                featureGenesTable:[],//INIT_STATE.featureGenesTable,
                highlyFeatureGenes:{}//INIT_STATE.highlyFeatureGenes
            };
    

        case WebsocketActionTypes.CHANGE_CLEAR_CLUSTERS:
            return {
                ...state,
                cluster_projection_type:INIT_STATE.cluster_projection_type, // UMAP/TSNE
                cluster_scatter_2d:INIT_STATE.cluster_scatter_2d,
                cluster_markers:INIT_STATE.cluster_markers,
                cluster_markers_csv:INIT_STATE.cluster_markers_csv,
                cluster_scatter_3d:INIT_STATE.cluster_scatter_3d,
                cluster_list:[],
                cluster_sample_filter:{}
            };

        case WebsocketActionTypes.CHANGE_CLEAR_CLUSTER_MARKERS:
            return {
                ...state,
                cluster_markers:INIT_STATE.cluster_markers,
                cluster_markers_csv:INIT_STATE.cluster_markers_csv,
            };
            

        case WebsocketActionTypes.CHANGE_CLEAR_GENES_EXPRESSION:
            return {
                ...state,
                geneExpression_violin_genes:INIT_STATE.geneExpression_violin_genes,
                geneExpression_scatter_genes:INIT_STATE.geneExpression_scatter_genes,
                geneExpression_rawCountsType:INIT_STATE.geneExpression_rawCountsType                
            };

        case WebsocketActionTypes.CHANGE_CLEAR_DIFF_GENES_EXPRESSION:
            if (state.geneExpression_rawCountsType ==="metadatas")
            {
                return {
                    ...state,
                    geneExpression_table:INIT_STATE.geneExpression_table,
                    geneExpression_table_csv:INIT_STATE.geneExpression_table_csv
                };
            }
            else
            {
                return {
                    ...state,
                    geneExpression_table_from_cluster:INIT_STATE.geneExpression_table_from_cluster,
                };
            }

            

        case WebsocketActionTypes.CHANGE_CLEAR_PCA:
            return {
                ...state,
                pca_elbow:INIT_STATE.pca_elbow,
                pca_heatmaps:INIT_STATE.pca_heatmaps,
            };

        case WebsocketActionTypes.CHANGE_GENEEXPRESSION_RAW_COUNTS_TYPE:
            return {
                ...state,
                geneExpression_rawCountsType: action.payload
            };

        case WebsocketActionTypes.CHANGE_H5_TREEVIEW:
            return {
                ...state,
                h5_treeview: action.payload
            };    

        case WebsocketActionTypes.CHANGE_WEBSOCKET_ACK:
            return{ 
                ...state,
                websocketAck: action.payload
            };

        case WebsocketActionTypes.CHANGE_WEBSOCKET_SENDMESSAGE_ACK:
            return{ 
                ...state,
                websocketSendMessageAck: action.payload
            };

        case WebsocketActionTypes.CHANGE_WEBSOCKET_FORCE_RELOAD:
            return{ 
                ...state,
                webscoketForceReload: action.payload
            };


        case WebsocketActionTypes.CHANGE_FORCE_RELOAD_AFTER_LOAD_ANALYSIS:
            return{ 
                ...state,
                forceReloadAfterAnalysis: action.payload
            };

        case WebsocketActionTypes.CHANGE_LOAD_ALL_DATA:
            //console.log("bb state:", state)
            //console.log("bb action.payload:", action.payload)
            //console.log("bb INIT_STATE:", INIT_STATE)
            var newState = {}
            Object.assign(newState, INIT_STATE, action.payload, {experiments_tree:state.experiments_tree, forceReloadAfterAnalysis:state.forceReloadAfterAnalysis+1 })
            return {
                // we don't change data (list of experiments and samples)
                //...Object.assign(action.payload, INIT_STATE, {experiments_tree:state.experiments_tree})
            
               ...newState // if new variable in INIT_STATE, it will prevent crash
            };

         

        default:
            return state;
    }
};

export default WebSocket;
