// constants
import { WebsocketActionTypes } from './constants';

export interface WebsocketActionType<TPayload> {
    type: WebsocketActionTypes.CHANGE_CONNECTION_STATUS
        | WebsocketActionTypes.CHANGE_LAST_MESSAGE
        | WebsocketActionTypes.CHANGE_ASK_USER_DATA
        | WebsocketActionTypes.CHANGE_DATA
        | WebsocketActionTypes.CHANGE_CURRENT_SAMPLE_EXP
        | WebsocketActionTypes.CHANGE_CURRENT_SAMPLE_ONLY
        | WebsocketActionTypes.CHANGE_QC_VIOLIN_RAW_COUNTS
        | WebsocketActionTypes.CHANGE_QC_VIOLIN_GENES_NB_PER_CELL
        | WebsocketActionTypes.CHANGE_QC_VIOLIN_MITO_PERCENT
        | WebsocketActionTypes.CHANGE_QC_CELLS_NUMBER_AFTER_FILTERS
        | WebsocketActionTypes.CHANGE_QC_GENES_NUMBER_AFTER_FILTERS
        | WebsocketActionTypes.CHANGE_QC_SCATTER_GENENB_COUNTS
        | WebsocketActionTypes.CHANGE_QC_SCATTER_MTPERCENT_COUNTS
        | WebsocketActionTypes.CHANGE_SAMPLES_NAME
        | WebsocketActionTypes.CHANGE_QC_COMPLEXITY_COMPLEXITY
        | WebsocketActionTypes.CHANGE_QC_COMPLEXITY_COUNTS
        | WebsocketActionTypes.CHANGE_QC_COMPLEXITY_FEATURES
        | WebsocketActionTypes.CHANGE_QC_COMPLEXITY_PERCENTMT
        | WebsocketActionTypes.CHANGE_HIGHLY_FEATURE_GENES
        | WebsocketActionTypes.CHANGE_FEATURE_GENES_TABLE
        | WebsocketActionTypes.CHANGE_PCA_ELBOW
        | WebsocketActionTypes.CHANGE_PCA_HEATMAPS
        | WebsocketActionTypes.CHANGE_CLUSTER_PROJETION_TYPE
        | WebsocketActionTypes.CHANGE_CLUSTER_SCATTER_2D
        | WebsocketActionTypes.CHANGE_CLUSTER_LIST
        | WebsocketActionTypes.CHANGE_CLUSTER_SAMPLE_FILTER
        | WebsocketActionTypes.CHANGE_CLUSTER_MARKERS
        | WebsocketActionTypes.CHANGE_CLUSTER_MARKERS_CSV
        | WebsocketActionTypes.CHANGE_CLUSTER_MARKERS_CSV_FULL_COPY
        | WebsocketActionTypes.CHANGE_CLUSTER_SCATTER_3D
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_GENES_NAME
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_VIOLIN_GENES
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_SCATTER_GENES
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_RAW_COUNTS_TYPE
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE_CSV
        | WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE_FROM_CLUSTER
        | WebsocketActionTypes.CHANGE_CLEAR_ALL
        | WebsocketActionTypes.CHANGE_CLEAR_GENES_EXPRESSION
        | WebsocketActionTypes.CHANGE_CLEAR_DIFF_GENES_EXPRESSION
        | WebsocketActionTypes.CHANGE_CLEAR_CLUSTERS
        | WebsocketActionTypes.CHANGE_CLEAR_CLUSTER_MARKERS
        | WebsocketActionTypes.CHANGE_TEST_GRAPH
        | WebsocketActionTypes.CHANGE_CLEAR_DE_INTEGRATION
        | WebsocketActionTypes.CHANGE_CLEAR_PCA
        | WebsocketActionTypes.CHANGE_H5_TREEVIEW
        | WebsocketActionTypes.CHANGE_LOAD_ALL_DATA
        | WebsocketActionTypes.CHANGE_WEBSOCKET_ACK
        | WebsocketActionTypes.CHANGE_WEBSOCKET_FORCE_RELOAD
        | WebsocketActionTypes.CHANGE_WEBSOCKET_SENDMESSAGE_ACK
        | WebsocketActionTypes.CHANGE_FORCE_RELOAD_AFTER_LOAD_ANALYSIS;
    payload?: TPayload;
}

/*
interface UserData {
    connection_connected: boolean;
    lastMessage: string;
}
*/

export const changeConnectionStatus = (connection_connected: boolean): WebsocketActionType<boolean> => ({
    type: WebsocketActionTypes.CHANGE_CONNECTION_STATUS,
    payload: connection_connected,
});

export const changeLastMessage = (lastMessage: string): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_LAST_MESSAGE,
    payload: lastMessage,
});

export const askUserData = (ask_user_data: boolean): WebsocketActionType<boolean> => ({
    type: WebsocketActionTypes.CHANGE_ASK_USER_DATA,
    payload: ask_user_data,
});

export const updataData = (data: any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_DATA,
    payload: data,
});

export const updataCurrentSampleExp = (currentSamplesExp: any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CURRENT_SAMPLE_EXP,
    payload: currentSamplesExp,
});

export const updataCurrentSampleOnly = (currentSamplesOnlyWithoutSpaces: any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CURRENT_SAMPLE_ONLY,
    payload: currentSamplesOnlyWithoutSpaces,
});

export const updataQcViolinRawCounts = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_VIOLIN_RAW_COUNTS,
    payload: {sampleName, data},
});

export const updataQcViolinGenesNbPerCell = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_VIOLIN_GENES_NB_PER_CELL,
    payload: {sampleName, data},
});

export const updataQcViolinPercentMt = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_VIOLIN_MITO_PERCENT,
    payload: {sampleName, data},
});

export const updataQcCellsNumberAfterFilters = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_CELLS_NUMBER_AFTER_FILTERS,
    payload: data,
});

export const updataQcGenesNumberAfterFilters = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_GENES_NUMBER_AFTER_FILTERS,
    payload: data,
});

export const updataQcScatterGeneNbCounts = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_SCATTER_GENENB_COUNTS,
    payload: {sampleName, data},
});

export const updataQcScattePercentMtCounts = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_SCATTER_MTPERCENT_COUNTS,
    payload: {sampleName, data},
});

export const updataSamplesName = (sampleName:string): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_SAMPLES_NAME,
    payload: sampleName,
});

export const updataQcComplexityComplexity = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_COMPLEXITY_COMPLEXITY,
    payload: {sampleName, data},
});

export const updataQcComplexityCounts = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_COMPLEXITY_COUNTS,
    payload: {sampleName, data},
});

export const updataQcComplexityFeatures = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_COMPLEXITY_FEATURES,
    payload: {sampleName, data},
});

export const updataQcComplexityPercentMt = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_QC_COMPLEXITY_PERCENTMT,
    payload: {sampleName, data},
});

export const updataHighlyFeatureGenes = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_HIGHLY_FEATURE_GENES,
    payload: data,
});

export const updataFeatureGenesTable = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_FEATURE_GENES_TABLE,
    payload: data,
});


export const updataPcaElbow = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_PCA_ELBOW,
    payload: data
});

export const updataHeatmaps = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_PCA_HEATMAPS,
    payload: data,
});

export const updataClusterProjectionType = (data:string): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_PROJETION_TYPE,
    payload: data,
});

export const updataClusterScatter2d = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_SCATTER_2D,
    payload: data,
});

export const changeClusterList = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_LIST,
    payload: data,
});

export const changeClusterSampleFilter = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_SAMPLE_FILTER,
    payload: data,
});

export const updataClusterMarkers = (clusterName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_MARKERS,
    payload: {clusterName, data},
});

export const updataClusterMarkersCSv = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_MARKERS_CSV,
    payload: data,
});

export const updataClusterMarkersCSvFullCopy = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_MARKERS_CSV_FULL_COPY,
    payload: data,
});

export const updataClusterScatter3d = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_CLUSTER_SCATTER_3D,
    payload: data,
});

export const updataGenesExpressionGenesName = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_GENES_NAME,
    payload: data,
});

export const updataTestGraph = (sampleName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_TEST_GRAPH,
    payload: {sampleName, data},
});

export const updataGenesExpressionViolinGenes = (geneName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_VIOLIN_GENES,
    payload: {geneName, data},
});

export const updataGenesExpressionScatterGenes = (geneName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_SCATTER_GENES,
    payload: {geneName, data},
});

export const updataGenesExpressionTable = (clusterName:string, data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE,
    payload: {clusterName, data},
});

export const updataGenesExpressionTableCsv = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE_CSV,
    payload: data,
});

export const updataGenesExpressionTableFromCluster = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_TABLE_FROM_CLUSTER,
    payload: data,
});

export const updataGenesExpressionRawCountType = (type:string): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_GENEEXPRESSION_RAW_COUNTS_TYPE,
    payload: type,
});

export const clearAllGraphs = (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_ALL,
    payload: undefined,
});

export const clearGenesExpressionGraphs = (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_GENES_EXPRESSION,
    payload: undefined,
});

export const clearDiffGenesExpressionGraphs = (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_DIFF_GENES_EXPRESSION,
    payload: undefined,
});

export const clearClustersGraphs = (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_CLUSTERS,
    payload: undefined,
});

export const clearClusterMarkers= (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_CLUSTER_MARKERS,
    payload: undefined,
});

export const clearDeIntegrationData = (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_DE_INTEGRATION,
    payload: undefined,
});
 
export const clearPcaData = (): WebsocketActionType<string> => ({
    type: WebsocketActionTypes.CHANGE_CLEAR_PCA,
    payload: undefined,
});

export const updateH5Treeview = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_H5_TREEVIEW,
    payload: data,
});

export const updateWebsocketAck = (data:number): WebsocketActionType<number> => ({
    type: WebsocketActionTypes.CHANGE_WEBSOCKET_ACK,
    payload: data,
});

export const updateWebsocketSendMessageAck = (data:number): WebsocketActionType<number> => ({
    type: WebsocketActionTypes.CHANGE_WEBSOCKET_SENDMESSAGE_ACK,
    payload: data,
});

export const updateWebsocketForceReload = (data:number): WebsocketActionType<number> => ({
    type: WebsocketActionTypes.CHANGE_WEBSOCKET_FORCE_RELOAD,
    payload: data,
});

export const updateAllWebsocketData = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_LOAD_ALL_DATA,
    payload: data,
});

export const updateForceReloadAfterAnalysis = (data:any): WebsocketActionType<any> => ({
    type: WebsocketActionTypes.CHANGE_FORCE_RELOAD_AFTER_LOAD_ANALYSIS,
    payload: data,
});
