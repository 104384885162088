// constants
import { AuthActionTypes } from './constants';

export interface AuthActionType {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.CHANGE_PASSWORD
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SIGNUP_USER
        | AuthActionTypes.CHANGE_VALIDATION_STATUS
        | AuthActionTypes.EMAIL_REGISTERD
        | AuthActionTypes.PASSWORD_REGISTERD
        | AuthActionTypes.REGISTRATION_CONFIRMED
        | AuthActionTypes.CHANGE_ROLE;
    payload: {} | string;
}

interface UserData {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
    email_registered: string;
    password_registered: string;
}

// common success
export const authApiResponseSuccess = (actionType: string, data: UserData | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (email: string, password: string, validation_key: string): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: {  email, password, validation_key },
});

export const forgotPassword = (email: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const changePassword = (password_old: string, password_new: string, user_token_server: string): AuthActionType => ({
    type: AuthActionTypes.CHANGE_PASSWORD,
    payload: { password_old, password_new, user_token_server },
});

export const changeValidationKeyStatus = (key_asking_sent: boolean): AuthActionType => ({
    type: AuthActionTypes.CHANGE_VALIDATION_STATUS,
    payload: { key_asking_sent },
});


export const changeRole = (strRole: string): AuthActionType => ({
    type: AuthActionTypes.CHANGE_ROLE,
    payload:strRole,
});

export const setEmailRegistered = (email: string): AuthActionType => ({
    type: AuthActionTypes.EMAIL_REGISTERD,
    payload:email,
});

export const setPasswordRegisterd = (password: string): AuthActionType => ({
    type: AuthActionTypes.PASSWORD_REGISTERD,
    payload:password,
});

export const confirmRegistration = (isRegistered: number): AuthActionType => ({
    type: AuthActionTypes.REGISTRATION_CONFIRMED,
    payload: isRegistered,
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
