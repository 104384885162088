// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { AuthActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    loading: false,
    registerError: "",
    validation_key_sent: false,
    role:"",
    email_registered:"",// use after register to autofill password/email
    password_registered:"",// use after register to autofill password/email
    resgistration_confirmed:0
};

interface UserData {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
    resgistration_confirmed: number;
    email_registered: string;
    password_registered: string;
}

interface AuthActionType {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.CHANGE_VALIDATION_STATUS
        | AuthActionTypes.CHANGE_ROLE
        | AuthActionTypes.REGISTRATION_CONFIRMED
        | AuthActionTypes.EMAIL_REGISTERD
        | AuthActionTypes.PASSWORD_REGISTERD;
    payload: {
        actionType?: string;
        data?: UserData | {};
        error?: string;
        registerError?: string;
    };
}

interface State {
    user?: UserData | {};
    loading?: boolean;
    value?: boolean;
    registerError?: string;
    validation_key_sent?: boolean;
    role?:string;
    resgistration_confirmed?:number
    email_registered?:string;
    password_registered?:string;
}

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
    switch (action.type) {


        case AuthActionTypes.EMAIL_REGISTERD:
            return{
                ...state,
                email_registered:action.payload
            }

        case AuthActionTypes.PASSWORD_REGISTERD:
            return{
                ...state,
                password_registered:action.payload
            }

        case AuthActionTypes.REGISTRATION_CONFIRMED:
            return{
                ...state,
                resgistration_confirmed:action.payload
            }

        case AuthActionTypes.CHANGE_ROLE:
            return{
                ...state,
                role:action.payload
            }

        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        loading: false,
                        userSignUp: true,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordReset: true,
                    };
                }
                case AuthActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        changePasswordSuccess: action.payload.data,
                        loading: false
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                }
                
                default:
                    return { ...state };
            }

        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };
        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userSignUp: false,
                userLoggedIn: false,
                passwordReset: false,
                passwordChange: false,
                resetPasswordSuccess: null,
                changePasswordSuccess: null,
            };

        case AuthActionTypes.CHANGE_VALIDATION_STATUS:
            return {
                ...state,
                validation_key_sent: action.payload,
            };
        
        default:
            return { ...state };
    }
};

export default Auth;
