// action constants
import { FormAnalysisActionType } from './actions';
import { FormAnalysisActionTypeConst } from './constants';

interface FormAnalysisData {
    dataSelection_experiment: any;
    dataSelection_samples: any;
    dataSelection_selection_experiment:string;
    
    filter1_minCellPerGene: number;
    filter1_minGenePerCell : number;
    filter2_diffGenesPerCellMin: number;
    filter2_diffGenesPerCellMax: number;
    filter2_maxMitoPercent: number;

    qc_filters: any;
    qc_filters_defaultvalues:any,
    qc_filters_key:number,

    genes_number_used:number;
    integration_merge_enabled:boolean;
    integration_merge_metadata:string;

    clustering_projection: string;
    clustering_projection_louvain_resolution: number;
    clustering_projection_pc_number: number;
    clustering_tsne_perplexity: number;
    clustering_tsne_early_exaggeration: number;

    vSubClustering: any;
    vSubClusteringSent: any;

    geneExpression_genesNameList:any;
    geneExpression_rawCounts:any;
    geneExpression_sortedScatter:any;
    geneExpression_scatter_color:any;
    geneExpression_scatter_marker_size:any;

    clustering_projection_last_sent: string;
    clustering_projection_louvain_resolution_last_sent: number;
    clustering_projection_pc_number_last_sent: number;
    clustering_tsne_perplexity_last_sent: number;
    clustering_tsne_early_exaggeration_last_sent: number;

    diffGenesExpressionMetadatas:any;
    diffGenesExpressionMetadatasVs:any;
    diffGenesExpressionClusters:any;

    diffGenesExpressionType:string; // metadatas or clusters
    diffGenesExpressionClustersClusters:any;
    diffGenesExpressionClustersClustersVs:any;

    vUserDataSaves:any;// list of full saves (saved remotly)
    dictUserMemory:any; // [experiment] => memory MB
    dictUserSavedAnalysisMemory:any; // [analysis] => memory MB
    dictUserRights:any; // {"max_memory_gb":1}
    dictWebServerAdminInfos:any; // {"available_mem_mb":x, "total_mem_mb":y}
}

const INIT_STATE = {
    dataSelection_experiment:{}, // {value:0, label:"experiment toto"}  => selected experiment
    dataSelection_samples:[], // [0,1,5] checked samples on table
    dataSelection_selection_experiment: "", // experiment for the selected samples dataSelection_samples
    filter1_minCellPerGene: 3,
    filter1_minGenePerCell: 200,
    filter2_diffGenesPerCellMin: 150,
    filter2_diffGenesPerCellMax: 2800,
    filter2_maxMitoPercent: 5.0,

    qc_filters:{}, // [sample_name][umi/NFeatures/mito][min/max]
    qc_filters_defaultvalues:{},
    qc_filters_key:0,
    /* {"umi":{"min":100,"max":10000},
    "NFeatures":{"min":200,"max":20000},
    "mito":{"min":0.0,"max":5.0}},
    */

    genes_number_used:2000,
    integration_merge_enabled:false,
    integration_merge_metadata:{},

    clustering_projection: "UMAP",
    clustering_projection_louvain_resolution: 2.0,
    clustering_projection_pc_number: 5,
    clustering_tsne_perplexity: 30,
    clustering_tsne_early_exaggeration: 12,
    geneExpression_genesNameList:[],
    geneExpression_rawCounts:{value: 0, label: 'Normalized counts'},
    geneExpression_sortedScatter:{value: 0, label: 'Apply cells sorting'},
    geneExpression_scatter_color:{value: 'Viridis', label: 'Viridis'},
    geneExpression_scatter_marker_size:{value: 2, label: '2'},

    clustering_projection_last_sent: "",
    clustering_projection_louvain_resolution_last_sent: 0.0,
    clustering_projection_pc_number_last_sent: 0,
    clustering_tsne_perplexity_last_sent: 0,
    clustering_tsne_early_exaggeration_last_sent: 0,

    vSubClustering: [], // [{cluster:"0", "resolution:1.75", "applied":false}, {cluster:"5", "resolution:2.75", "applied":true}]
    vSubClusteringSent: [],

    diffGenesExpressionMetadatas:[],
    diffGenesExpressionMetadatasVs:[],
    diffGenesExpressionClusters:[],
    diffGenesExpressionType:"metadatas", // metadatas or clusters
    diffGenesExpressionClustersClusters:[],
    diffGenesExpressionClustersClustersVs:[],

    vUserDataSaves:[],
    dictUserMemory:{},
    dictUserSavedAnalysisMemory:{},
    dictUserRights:{},
    dictWebServerAdminInfos:{},
};

const FormAnalysis = (state: FormAnalysisData = INIT_STATE, action: FormAnalysisActionType<number | string | null | any >) => {
    switch (action.type) {
        case FormAnalysisActionTypeConst.CHANGE_DATASELECTION_EXPERIMENT:
            return {
                ...state,
                dataSelection_experiment: action.payload
            };   
        case FormAnalysisActionTypeConst.CHANGE_DATASELECTION_SAMPLES:
            return {
                ...state,
                dataSelection_samples: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_DATASELECTION_SELECTION_EXPERIMENT:
            return {
                ...state,
                dataSelection_selection_experiment: action.payload
            };  
            
        case FormAnalysisActionTypeConst.CHANGE_FILTER1_MIN_CELL_PER_GENE:
            return {
                ...state,
                filter1_minCellPerGene: action.payload,
            };
        case FormAnalysisActionTypeConst.CHANGE_FILTER1_MIN_GENE_PER_CELL:
            return {
                ...state,
                filter1_minGenePerCell: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_FILTER2_DIFF_GENES_PER_CELL_MIN:
            return {
                ...state,
                filter2_diffGenesPerCellMin: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_FILTER2_DIFF_GENES_PER_CELL_MAX:
            return {
                ...state,
                filter2_diffGenesPerCellMax: action.payload
            };
        
        case FormAnalysisActionTypeConst.CHANGE_FILTER2_MAX_MITO_PERCENT:
            return {
                ...state,
                filter2_maxMitoPercent: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_QC_FILTERS:
            return {
                ...state,
                qc_filters: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_QC_FILTERS_DEFAULT:
            return {
                ...state,
                qc_filters_defaultvalues: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_QC_FILTERS_KEY:
            return {
                ...state,
                qc_filters_key: action.payload
            };
            
        case FormAnalysisActionTypeConst.CHANGE_GENES_NUMBER:
            return {
                ...state,
                genes_number_used: action.payload
            };
        
        case FormAnalysisActionTypeConst.CHANGE_INTEGRATION_MERGE_ENABLE:
            return {
                ...state,
                integration_merge_enabled: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_INTEGRATION_MERGE_METADATA:
            return {
                ...state,
                integration_merge_metadata: action.payload
            };


        case FormAnalysisActionTypeConst.CHANGE_CLUSTERING_LAST_SENT:
            return {
                ...state,
                clustering_projection_last_sent:                    action.payload.projection,
                clustering_projection_louvain_resolution_last_sent: action.payload.louvain_resolution,
                clustering_projection_pc_number_last_sent:          action.payload.pc_number,
                clustering_tsne_perplexity_last_sent:               action.payload.tsne_perplexity,
                clustering_tsne_early_exaggeration_last_sent:       action.payload.tsne_early_exaggeration
            };      

        //  {projection, louvain_resolution, pc_number, tsne_perplexity,tsne_early_exaggeration}

        case FormAnalysisActionTypeConst.CHANGE_SUBCLUSTERING:
            return {
                ...state,
                vSubClustering: action.payload
            };   

        case FormAnalysisActionTypeConst.CHANGE_SUBCLUSTERING_SENT:
            return {
                ...state,
                vSubClusteringSent: action.payload
            };   

        case FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION:
            return {
                ...state,
                clustering_projection: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION_LOUVAIN_RESO:
            return {
                ...state,
                clustering_projection_louvain_resolution: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_CLUSTERING_PROJECTION_PC_NUMBER:
            return {
                ...state,
                clustering_projection_pc_number: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_CLUSTERING_TSNE_PERPLEXITY:
            return {
                ...state,
                clustering_tsne_perplexity: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_CLUSTERING_TSNE_EARLY_EXAGGERATION:
            return {
                ...state,
                clustering_tsne_early_exaggeration: action.payload
            };     
        
        case FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_GENES_NAME_LIST:
            return {
                ...state,
                geneExpression_genesNameList: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_RAW_COUNTS:
            return {
                ...state,
                geneExpression_rawCounts: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SORTED_SCATTER:
            return {
                ...state,
                geneExpression_sortedScatter: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SCATTER_COLOR:
            return {
                ...state,
                geneExpression_scatter_color: action.payload
            };      

        case FormAnalysisActionTypeConst.CHANGE_GENEEXPRESSION_SCATTER_MARKER_SIZE:
            return {
                ...state,
                geneExpression_scatter_marker_size: action.payload
            };      
            
        case FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_METADATAS:
            return {
                ...state,
                diffGenesExpressionMetadatas: action.payload
            };
        
        case FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_METADATAS_VS:
            return {
                ...state,
                diffGenesExpressionMetadatasVs: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS:
            return {
                ...state,
                diffGenesExpressionClusters: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS:
            return {
                ...state,
                diffGenesExpressionClustersClusters: action.payload
            };
        
        case FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS_VS:
            return {
                ...state,
                diffGenesExpressionClustersClustersVs: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_DIFFGENEEXPRESSION_TYPE:
            return {
                ...state,
                diffGenesExpressionType: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_CLEAR_DIFF_GENES_EXPRESSION_2:
            return {
                ...state,
                diffGenesExpressionMetadatas: INIT_STATE.diffGenesExpressionMetadatas,
                diffGenesExpressionMetadatasVs: INIT_STATE.diffGenesExpressionMetadatasVs,
                diffGenesExpressionClusters: INIT_STATE.diffGenesExpressionClusters,
                diffGenesExpressionType: INIT_STATE.diffGenesExpressionType,
                diffGenesExpressionClustersClusters: INIT_STATE.diffGenesExpressionClustersClusters,
                diffGenesExpressionClustersClustersVs: INIT_STATE.diffGenesExpressionClustersClustersVs,
            };
        
        case FormAnalysisActionTypeConst.CHANGE_CLEAR_SUB_CLUSTER:
            return {
                ...state,
                vSubClustering: [],
                vSubClusteringSent: [],
            };

        case FormAnalysisActionTypeConst.CHANGE_CLEAR_ALL:
            return {
                ...INIT_STATE
            };

        case FormAnalysisActionTypeConst.CHANGE_LOAD_ALL_DATA:
            var newState = {}
            Object.assign(newState, INIT_STATE, action.payload, {vUserDataSaves:state.vUserDataSaves,
                dictUserMemory:state.dictUserMemory,
                dictUserSavedAnalysisMemory:state.dictUserSavedAnalysisMemory,
                dictUserRights:state.dictUserRights,
                dictWebServerAdminInfos:state.dictWebServerAdminInfos})
            return {
                ...newState
            };

        case FormAnalysisActionTypeConst.CHANGE_USER_SAVES_DATA:
            return {
                ...state,
                vUserDataSaves: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_USER_DATA_MEMORY_USAGE:
            return {
                ...state,
                dictUserMemory: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_USER_DATA_SAVED_ANALYSIS_MEMORY_USAGE:
            return {
                ...state,
                dictUserSavedAnalysisMemory: action.payload
            };

        case FormAnalysisActionTypeConst.CHANGE_USER_RIGHTS:
            return {
                ...state,
                dictUserRights: action.payload
            };
            
        case FormAnalysisActionTypeConst.CHANGE_WEBSERVER_ADMIN_INFOS:
            return {
                ...state,
                dictWebServerAdminInfos: action.payload
            };    
            
        default:
            return state;
    }
};

export default FormAnalysis;
