export enum AuthActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER = '@@auth/LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    SIGNUP_USER = '@@auth/SIGNUP_USER',
    CHANGE_VALIDATION_STATUS = '@@auth/CHANGE_VALIDATION_STATUS',
    FORGOT_PASSWORD = '@@auth/FORGOT_PASSWORD',
    CHANGE_PASSWORD = '@@auth/CHANGE_PASSWORD',
    CHANGE_ROLE = '@@auth/CHANGE_ROLE',
    EMAIL_REGISTERD = '@@auth/EMAIL_REGISTERD', 
    PASSWORD_REGISTERD = '@@auth/PASSWORD_REGISTERD', 
    REGISTRATION_CONFIRMED = '@@auth/REGISTRATION_CONFIRMED', 
    RESET = '@@auth/RESET',
}
