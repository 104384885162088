// WebSocket.js
import React, {useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';

//import { ComputeStepContext } from  '../pages/apps/common/ComputeStep';
import {
   // changeLastMessage,
    changeConnectionStatus,
    askUserData,
    updataData,
    changeAllMetadatas,
    changeAllMetadatasValues,
    changeQcFilter,
    updataQcViolinRawCounts,
    updataQcViolinGenesNbPerCell,
    updataQcViolinPercentMt,
    updataQcCellsNumberAfterFilters,
    changeDefaultQcFilter,
    updataQcGenesNumberAfterFilters,
    updataQcScatterGeneNbCounts,
    updataQcScattePercentMtCounts,
    updataSamplesName,
    updataQcComplexityComplexity,
    updataQcComplexityCounts,
    updataQcComplexityFeatures,
    updataQcComplexityPercentMt,
    updataHighlyFeatureGenes,
    updataFeatureGenesTable,
    updateProgress,
    updateProgressBackend,
    updateIsCompleted,
    updateIsLoading,
    cancelCurrentTask,
    updataPcaElbow,
    updataHeatmaps,
    updataClusterScatter2d,
    updataClusterMarkers,
    changeClusterSampleFilter,
    updataClusterMarkersCSv,
    updataClusterScatter3d,
    updataGenesExpressionGenesName,
    updataGenesExpressionViolinGenes,
    updataGenesExpressionScatterGenes,
    updataGenesExpressionTable,
    updataGenesExpressionTableCsv,
    updataGenesExpressionTableFromCluster,
    changeClusterList,
    changeRole,

    updateH5MatricesFiles ,
    updateH5BarcodesFile,
    updateH5FeaturesFile,

    //Params
    changeClusterNames,

    addNotification,
    setBackendStats,
    updateH5Treeview,
    updateAllWebsocketData,
    updateAllParamsData,
    updateAllFormAnalysisData,
    updateAllProgressData,
    updateUserSavesList,

    setUserSaveLoadLoading
    
    
} from '../redux/actions';


const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {

    console.log("Websockets init")
    let websocket_computation = null;
    let ws;
    let connectInterval;// = setInterval(webSocketHeartbeat, 20000); //call check function after timeout

    //const { dispatch, appSelector } = useRedux();

    const dispatch = useDispatch();

    const user = useSelector(state => state.Auth.user)
    const clusters_clusters_user_rename = useSelector(state => state.Params.clusters_clusters_user_rename)

    const loadingProgress = useSelector(state => state.Progress.progress)
    

   
    const sendMessage = ( message) => {

        console.log("ws::sendMessage msg: ", message)
        websocket_computation.send(message)

        //dispatch(updateChatLog(message));
    }

    
    const askUserDataWeb = (message) =>
    {
        console.log("WebsocketComputation askUserData")
        websocket_computation.send(message)
        // dispatch(askUserData(true));
    }

    const webSocketHeartbeat =() => // if no data sent during more than 60sec wss websocket going to close
    {
        console.log("webSocketHeartbeat")
        if (websocket_computation !== null )
        {
            try {
                console.log("webSocketHeartbeat send heartbeat")
                websocket_computation.send("heartbeat")
            }
            catch(error)
            {
                //console.log("webSocketHeartbeat catch, ",error)
            }
        }
        
    }

    const connectWebSocket = () =>
    {
        console.log("connectWebSocket websocket_computation === null :",websocket_computation === null)
    
        let isSocketConnected = websocket_computation !== null &&  websocket_computation.readyState === WebSocket.connected
        console.log('connectWebSocket isSocketConnected:',isSocketConnected)

        
       if (!isSocketConnected)
       {
            //console.log("new WebSocket !!")
            //websocket_computation = new WebSocket(config.WS_URL);
       }
       
       clearTimeout(connectInterval);
       connectInterval = setInterval(webSocketHeartbeat, 20000); //call check function after timeout
        

        /*
        setTimeout(function() {
            this.connectWebSocket();
        }
        .bind(this)
        , 1000);
        */

         websocket_computation.onmessage = evt => { 
        
        console.log("websocket::onmessage ", evt.data)
        var dataFromSocket = evt.data.split('|')
 
        if (dataFromSocket.length ===2)
        {
            let args = dataFromSocket[0].split(';')

            if (args[0] === 'user_data_tree')
            {
                let access_level = args[1]
                console.log("websocket::onmessage user_data_tree!! access_level:",access_level);
                dispatch(askUserData(true));
                dispatch(changeRole(access_level))

                let data = JSON.parse(dataFromSocket[1])
                
                console.log("websocket::onmessage data:", data)
                dispatch(updataData(data))

                return;
            }
            else if (args[0] === 'user_metadatas_struct')
            {
                console.log("websocket::onmessage user_metadatas_struct!!: ", dataFromSocket[1], dataFromSocket[1].length);

                if (dataFromSocket[1].length >2)
                {
                    let data = JSON.parse(dataFromSocket[1])
                
                    console.log("websocket::onmessage data:", data)
                    dispatch(changeAllMetadatas(data))
                }

                return;
            }
            else if (args[0] === 'user_metadatas_values')
            {
                console.log("websocket::onmessage user_metadatas_values!! ", dataFromSocket[1], dataFromSocket[1].length);

                if (dataFromSocket[1].length >2 )
                {
                    let data = JSON.parse(dataFromSocket[1])
                    
                    console.log("websocket::onmessage data:", data)
                    dispatch(changeAllMetadatasValues(data))
                }

                return;
            }
            else if (dataFromSocket[0] === "progress")
            {
                
                let args = dataFromSocket[1].split(';')

                if (args.length === 2)
                {
                    var stepName = args[0]               
                    var progress  = parseInt(args[1])
                    console.log("onNewWebSocketMessage stepName ", stepName," progress:",progress )

                    if (stepName === "saveLoadBackend")
                    {
                        console.log("onNewWebSocketMessage saveLoadBackend !!! ", progress)
                        if (progress === 100)
                        {
                            dispatch(setUserSaveLoadLoading(false))
                        }

                        dispatch(updateProgressBackend(progress))
                    }
                    else
                    {
                        if (progress ===100)
                        {
                            console.log("onNewWebSocketMessage ",stepName," updateIsCompleted")
                            dispatch(updateIsCompleted(stepName, true))
                            dispatch(updateIsLoading(stepName, false))

                            if (stepName ==="highlyFeatureVariables_step_4" || stepName ==="DE_Integration_step_2")
                            {
                                console.log("onNewWebSocketMessage ask PCA_step_5 !!")
                                
                                dispatch(updateIsLoading("PCA_step_5", true))
                            }
                        }
    
                        if (stepName in loadingProgress && loadingProgress[stepName] !== progress)
                            dispatch(updateProgress(stepName, progress))
                    }


                }                

            }
            else if (dataFromSocket[0] === "error")
            {
                console.log("onNewWebSocketMessage::error !")

                var errorList = dataFromSocket[1].split(';')

                if (errorList.length === 2)
                {
                    console.log("onNewWebSocketMessage::error :", errorList[0], " ", errorList[1]) 

                    var iMsgType = parseInt(errorList[0])
                    var variant_type = "error"
                    var msg_duration = 10000

                    switch(iMsgType)
                    {
                        case 1:
                            
                            variant_type = "error"
                            msg_duration = 10000
                        break;

                        case 2:
                            variant_type = "warning"
                            msg_duration = 10000
                        break;

                        case 3:
                            variant_type = "info"
                            msg_duration = 5000
                        break;

                        case 4:
                            variant_type = "success"
                            msg_duration = 5000
                        break;

                        default:
                            console.log("Warning unexpected case")
                            variant_type = "error"
                            msg_duration = 10000
                        break;
                    }

                    if (variant_type ==="error")
                    {
                        dispatch(cancelCurrentTask())

                        dispatch(setUserSaveLoadLoading(false))
                    }    
                
                    
                    dispatch(addNotification(
                        {description:errorList[1],
                        type:variant_type,
                        delay:msg_duration}
                    ))

                }
            }
            else if (args[0] === 'GenesNb')
            {
                console.log("websocket::onmessage GenesNb !");
                console.log("websocket::onmessage GenesNb data:", dataFromSocket[1]);
                var data = JSON.parse(dataFromSocket[1])
                console.log("websocket::onmessage GenesNb data=",data);
                dispatch(updataQcGenesNumberAfterFilters(data))
            }
            else if (args[0] === 'violin_QC')
            {
                let sample_name = args[2]
                console.log("websocket::onmessage violin_QC  sample_name=", sample_name);

                if (args[1] === 'Raw counts sum per cell')
                {
                    console.log("violin_QC Raw counts sum per cell !!");
                    let data = JSON.parse(dataFromSocket[1])
                    dispatch(updataQcViolinRawCounts(sample_name, data));    
                    return;
                }
                else if (args[1] === 'Genes number per cell')
                {
                    console.log("violin_QC Genes number per cell !!");
                    let data = JSON.parse(dataFromSocket[1])
                    dispatch(updataQcViolinGenesNbPerCell(sample_name, data));    
                    return;
                }
                else if (args[1] === 'Mitochondrial percentage per cell')
                {
                    console.log("violin_QC Mitochondrial percentage per cell !!");
                    let data = JSON.parse(dataFromSocket[1])
                    dispatch(updataQcViolinPercentMt(sample_name, data)); 
                    return;
                }
                
            }
            else if (args[0] === 'violin_QC_min_max')
            {
                console.log("websocket::onmessage violin_QC_min_max AAA data=",  dataFromSocket[1]);
                
                let data = JSON.parse(dataFromSocket[1])
                console.log("websocket::onmessage violin_QC_min_max !! data=", data);

                dispatch(changeQcFilter(data))
                // const clone = structuredClone(data);
                const clone = JSON.parse(JSON.stringify(data));
                dispatch(changeDefaultQcFilter(clone))
            }
            else if (args[0] === 'violin_QC_cells_number')
            {
                console.log("websocket::onmessage violin_QC_cells_number data=",  dataFromSocket[1]);
                
                let data = JSON.parse(dataFromSocket[1])

                dispatch(updataQcCellsNumberAfterFilters(data))
            }
            else if (args[0] === 'complexity')
            {
                let sample_name = args[2]
                console.log("websocket::onmessage complexity!! ",sample_name);

                if (args[1] === 'complexity')
                {
                    try{
                        console.log("Rcomplexity::complexity !!");
                        let data = JSON.parse(dataFromSocket[1])
                        dispatch(updataQcComplexityComplexity(sample_name, data));
                    } catch(e)
                    {
                        console.log("complexity catch error ", e);
                    }
                    return;
                }
                else if (args[1] === 'counts')
                {
                    try{
                        console.log("Rcomplexity::counts !!");
                        let data = JSON.parse(dataFromSocket[1])
                        dispatch(updataQcComplexityCounts(sample_name, data));
                    } catch(e)
                    {
                        console.log("counts catch error ", e);
                    }
                    return;
                }
                else if (args[1] === 'features')
                {
                    try{
                        console.log("Rcomplexity::features !!");
                        let data = JSON.parse(dataFromSocket[1])
                        dispatch(updataQcComplexityFeatures(sample_name, data));
                    } catch(e)
                    {
                        console.log("features catch error ", e);
                    }
                    return;
                }
                else if (args[1] === 'percentmt')
                {
                    try{
                        console.log("Rcomplexity::percentmt !!");
                        let data = JSON.parse(dataFromSocket[1])
                        dispatch(updataQcComplexityPercentMt(sample_name, data));
                    } catch(e)
                    {
                        console.log("percentmt catch error ", e);
                    }
                    return;
                }

            }
            else  if (args[0] === 'features_selection_variation')
            {
                console.log("websocket::onmessage features_selection_variation !");
                let data = JSON.parse(dataFromSocket[1])
                dispatch(updataHighlyFeatureGenes(data))
                return;
            }
            else  if (args[0] === 'featuresGenesTable')
            {
                console.log("websocket::onmessage featuresGenesTable !");
                let data = JSON.parse(dataFromSocket[1])
                dispatch(updataFeatureGenesTable(data))
                return;
            }
            else if (args[0] === 'scatter_plot')
            {
                let sample_name = args[2]
                console.log("websocket::onmessage scatter_plot!! sample_name: ", sample_name);
                dispatch(updataSamplesName(sample_name))
                //dispatch(updataTest(sample_name, args[0]))

                console.log("websocket::onmessage scatter_plot args[1]: ", args[1]);
                
                if (args[1] === 'Genes number - counts')
                {
                    console.log("Genes number - counts !!");
                    let dataJson = JSON.parse(dataFromSocket[1])

                    let scatter = {
                        data : dataJson,
                        xAxisName:"nCount",
                        yAxisName:'Genes number',
                        graphLegend: "sample "+sample_name
                    }
                    dispatch(updataQcScatterGeneNbCounts(sample_name, scatter));    
                    return;
                }
                else if (args[1] === 'Mitochondrial percentage - counts')
                {
                    console.log("Mitochondrial percentage - counts !!");
                    let dataJson = JSON.parse(dataFromSocket[1])

                    let scatter = {
                        data : dataJson,
                        xAxisName:"nCount",
                        yAxisName:'Mitochondrial percentage',
                        graphLegend: "sample "+sample_name
                    }
                    dispatch(updataQcScattePercentMtCounts(sample_name, scatter));    
                    return;
                }
            }
            else if (args[0] === "genes_names")
            {
                console.log("onNewWebSocketMessage genes_names")

                let dataJson = JSON.parse(dataFromSocket[1])
                
                let data = dataJson.map(x => {
                    return({value: x, label:x});
                })
                dispatch(updataGenesExpressionGenesName(data))

                /*
                this.setState({
                    genesNames: dataJson.map(x => {
                        return({title: x});
                    })
                })
                */
            }
            else if (args[0] === 'elbow')
            {
                console.log("elbow !!");
                let dataJson = JSON.parse(dataFromSocket[1])

                dispatch(updataPcaElbow(dataJson))
            }
            else if (args[0] === 'heatmap')
            {
                console.log("heatmap !!")
                let dataJson = JSON.parse(dataFromSocket[1])

                let PCHeatmapPathsTmp = []
                for (var i = 0; i < dataJson.length; i++)
                {
                    console.log("data ",i, " : ",dataJson[i]);
                    dataJson[i] = dataJson[i].replace('-', '/')
                    PCHeatmapPathsTmp.push(dataJson[i])
                }

                dispatch(updataHeatmaps(PCHeatmapPathsTmp))
            }
            else if (args[0] === 'cluster_2d_plotly')
            {
                console.log("cluster_2d !!")
                let dataJson = JSON.parse(dataFromSocket[1])

                /*
                let cluster_custom_names = []
                for(let i =0 ; i < dataJson.length ; i++)
                {
                    cluster_custom_names.push("Cluster "+(i+1))
                }

                dispatch(changeClusterNames(cluster_custom_names))
                */
                dispatch(updataClusterScatter2d(dataJson))
               
            }
            else if (args[0] === 'list_clusters_id')
            {
                console.log("list_clusters_id !!")
                let dataJson = JSON.parse(dataFromSocket[1])

                let cluster_custom_names = []
                for(let i =0 ; i < dataJson.length ; i++)
                {
                    if ( i in clusters_clusters_user_rename)
                    {
                        cluster_custom_names.push(clusters_clusters_user_rename[i])
                    }
                    else
                    {
                        cluster_custom_names.push(dataJson[i].name)
                    }
                    
                }

                dispatch(changeClusterNames(cluster_custom_names))

                dispatch(changeClusterList(dataJson))
               
            }
            else if (args[0] === 'sample_cluster_projection_filter')
            {
                console.log("sample_cluster_projection_filter !!")

                try
                {
                    let dataJson = JSON.parse(dataFromSocket[1])
                    dispatch(changeClusterSampleFilter(dataJson))
                }
                catch(e)
                {
                    console.log("sample_cluster_projection_filter parsing error", e)
                }
               
            }
            else if (args[0] === 'clustersMarkers')
            {
                let clusterName = args[1]
                console.log("clustersMarkers !! clusterName:", clusterName)
                let dataJson = JSON.parse(dataFromSocket[1])

                dispatch(updataClusterMarkersCSv(dataJson))
                dispatch(updataClusterMarkers(clusterName, dataJson))
                
                //dispatch(updataClusterScatter2d(dataJson))
            }
            else if (args[0] === 'clustersDifferentialGeneExpression')
            {
                let clusterName = args[1]
                
                console.log("clustersDifferentialGeneExpression !! clusterName:", clusterName, " len=",dataFromSocket[1].length)
                let dataJson = JSON.parse(dataFromSocket[1])

                console.log("clustersDifferentialGeneExpression ", dataJson)
                console.log("clustersDifferentialGeneExpression !! dataJson:")

                dispatch(updataGenesExpressionTableCsv(dataJson))
                dispatch(updataGenesExpressionTable(clusterName, dataJson))
            }   
            else if (args[0] === 'FromClusterDifferentialGeneExpression')
            {
                console.log("FromClusterDifferentialGeneExpression !!")
                let dataJson = JSON.parse(dataFromSocket[1])

                dispatch(updataGenesExpressionTableFromCluster(dataJson))
                //dispatch(updataGenesExpressionTable(clusterName, dataJson))
            }   
            
            else if (args[0] === "cluster_umap_3d")
            {
                console.log("onNewWebSocketMessage cluster_umap_3d")
    
                let dataJson = JSON.parse(dataFromSocket[1])

                dispatch(updataClusterScatter3d(dataJson))
            }
            else if (args[0] === "violin_genes")
            {
                let geneName = args[1]
                console.log("onNewWebSocketMessage violin_genes geneName:",geneName)
    
                let dataJson = JSON.parse(dataFromSocket[1])
      
                dispatch(updataGenesExpressionViolinGenes(geneName, dataJson))
            }
            else if (args[0] === "umpa_scatter_genes")
            {
                let geneName = args[1]
                console.log("onNewWebSocketMessage umap_scatter_genes geneName:",geneName)
    
                let dataJson = JSON.parse(dataFromSocket[1])

                dispatch(updataGenesExpressionScatterGenes(geneName, dataJson))
            }
            else if (args[0] === "backend_stats")
            {
                let dataJson = JSON.parse(dataFromSocket[1])

                console.log("onNewWebSocketMessage backend_stats:", dataJson)
                console.log("onNewWebSocketMessage backend_stats:", dataFromSocket[1])

                dispatch(setBackendStats(dataJson))
            }
            else if (args[0] === "h5treeview")
            {
                try
                {

                    console.log("onNewWebSocketMessage h5treeview !")
                    let dataJson = JSON.parse(dataFromSocket[1])
    
                    console.log("onNewWebSocketMessage h5treeview:", dataJson)
                    console.log("onNewWebSocketMessage h5treeview:", dataFromSocket[1])
    
                    dispatch(updateH5Treeview(dataJson))
                }
                catch(e)
                {
                    console.log("h5treeview parsing error")
                }
            }
            else if (args[0] === "h5auto_detected_files")
            {
                console.log("onNewWebSocketMessage h5auto_detected_files :", dataFromSocket[1])
                let vFiles =  dataFromSocket[1].split(",")
                if (vFiles.length === 5) // ["matrix/data", "matrix/indices", "matrix/indptr", "matrix/barcodes", "matrix/features/name"]
                {
                    console.log("onNewWebSocketMessage h5auto_detected_files files auto detected !")
                    dispatch(updateH5MatricesFiles([vFiles[0],vFiles[1],vFiles[2]]))
                    dispatch(updateH5BarcodesFile(vFiles[3]))
                    dispatch(updateH5FeaturesFile(vFiles[4]))
                }
                else
                {
                    console.log("onNewWebSocketMessage h5auto_detected_files no files found")
                }
                
            }
            else if (args[0] === "load_data")
            {
                console.log("onNewWebSocketMessage load_data :")


                let dataJson = JSON.parse(dataFromSocket[1])
                
                if ("params_store" in dataJson && 
                    "websocket_store" in dataJson && 
                    "formAnalysis_store" in dataJson && 
                    "progress_store" in dataJson )
                {
                    dispatch(updateAllParamsData(dataJson["params_store"]))
                    dispatch(updateAllWebsocketData(dataJson["websocket_store"]))
                    dispatch(updateAllFormAnalysisData(dataJson["formAnalysis_store"]))
                    dispatch(updateAllProgressData(dataJson["progress_store"]))

                    dispatch(addNotification(
                        {description:"Data successfuly loaded",
                        type:"success",
                        delay:5000}
                    ))
                }
                else
                {
                    dispatch(addNotification(
                        {description:"Data error loading",
                        type:"error",
                        delay:5000}
                    ))
                }
                
            }
            else if (args[0] === "user_saves_analysis")
            {
                console.log("onNewWebSocketMessage user_saves_analysis :", dataFromSocket[1])

                let dataJson = JSON.parse(dataFromSocket[1])

                dispatch(updateUserSavesList(dataJson))
            }


            
        }
 
        //dispatch(changeLastMessage(evt.data));
      
        };

        

        websocket_computation.onopen = evt => {
            console.log("websocket::onopen !!!")
            dispatch(changeConnectionStatus(true));

            

            if (user !==null)
            {
                console.log("websocket::onopen askUserDataWeb")
                askUserDataWeb("user_login;" + user.token_server);
            }           
        };

        websocket_computation.onclose = evt => {
            console.log("websocket::onclose")
            dispatch(changeConnectionStatus(false));
            setTimeout(function() {
                connectWebSocket();
            }
            .bind(this)
            , 1000);
           
        };

        websocket_computation.onerror = evt => {
            console.log("websocket::onerror ", evt)
        };

        ws = {
            socket: websocket_computation,
            sendMessage,
            askUserDataWeb,
            connectInterval
        }
    }

    /*
    onclose()
    {
        console.log("websocket::onClose")
        setTimeout(function() {
            connectWebSocket();
        }
        .bind(this)
        , 1000);
    }
    */




    if (!websocket_computation && user !==null)
    {
        connectWebSocket()
    }


    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )

}


/*
export default ({ children }) => {

    let socket = undefined;
    let ws;

    const dispatch = useDispatch();

    const sendMessage = ( message) => {

        console.log("send mesg", message)
        socket.emit("event://send-message", message);

        //dispatch(updateChatLog(message));
    }


    if (socket ===undefined) {

        //console.log("connect ws")
        socket = io.connect('ws://xxx.xxx.xxx.xx:8080')

        socket.on("event://get-message", (msg) => {
            console.log("ws msg received", msg)
            dispatch(changeLastMessage(msg));
            //const payload = JSON.parse(msg);
            //dispatch(updateChatLog(payload));
        })

        socket.on("disconnect", () => {
            console.log("Client disconnected");
            // clearInterval(interval);
          });

          socket.on("connection", () => {
            console.log("Client connected");
            // clearInterval(interval);
          });

        ws = {
            socket: socket,
            sendMessage
        }
    }


    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )

}
*/
