// action constants
import { ProgressActionType } from './actions';
import { ProgressActionTypeConst } from './constants';

interface progressData {
    isCompleted: any;
    progress: any;
    isLoading: any;
    backendStats: any;
    backendAllUsers: any;
    progressBackend: number;
}

const INIT_STATE = {
    isCompleted: {
        "updateFiles_step_1":false,
        "DE_Integration_step_2":false,
        "QC_step_3":false,
        "highlyFeatureVariables_step_4":false,
        "PCA_step_5":false,
        "clustering_step_6":false,
        "geneExpression_step_7":false,
        "differentialGeneExpression_step_8":false
    },
    progress: {
        "updateFiles_step_1":0,
        "DE_Integration_step_2":0,
        "QC_step_3":0,
        "highlyFeatureVariables_step_4":0,
        "PCA_step_5":0,
        "clustering_step_6":0,
        "geneExpression_step_7":0,
        "differentialGeneExpression_step_8":0
    },
    isLoading: {
        "updateFiles_step_1":false,
        "DE_Integration_step_2":false,
        "QC_step_3":false,
        "highlyFeatureVariables_step_4":false,
        "PCA_step_5":false,
        "clustering_step_6":false,
        "geneExpression_step_7":false,
        "differentialGeneExpression_step_8":false
        
    },
    backendStats:{},
    backendAllUsers:[],
    progressBackend:0
};

// this.stepsMonoSample = ["updateFiles_step_1",  "QC_step_3", "highlyFeatureVariables_step_4", "PCA_step_5", "clustering_step_6", "geneExpression_step_7"]
// this.stepsMultiSamples = ["updateFiles_step_1", "QC_step_3", "DE_Integration_step_2" ,  "PCA_step_5", "clustering_step_6", "geneExpression_step_7", "differentialGeneExpression_step_8"]


const Progress = (state: progressData = INIT_STATE, action: ProgressActionType<string | boolean | any | null>) => {
    switch (action.type) {
        case ProgressActionTypeConst.CHANGE_IS_COMPLETED:
            return {
                ...state,
                isCompleted:{
                    ...state.isCompleted,  [action.payload.stepName] :action.payload.data
                }                
            };

        case ProgressActionTypeConst.CHANGE_PROGRESS:
            return {
                ...state,
                progress:{
                    ...state.progress,  [action.payload.stepName] :action.payload.data
                }                
            };

        case ProgressActionTypeConst.CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading:{
                    ...state.isLoading,  [action.payload.stepName] :action.payload.data
                }                
            };

        case ProgressActionTypeConst.CHANGE_CANCEL_CURRENT_WORK:
            return {
                ...state,
                isLoading: INIT_STATE.isLoading,
                progress: INIT_STATE.progress                 
            };

        case ProgressActionTypeConst.CHANGE_CLEAR:
            return {
                ...state,
                isLoading: INIT_STATE.isLoading,
                progress: INIT_STATE.progress,
                isCompleted: INIT_STATE.isCompleted               
            };

        case ProgressActionTypeConst.CHANGE_BACKEND_STATS:
            return {
                ...state,
                backendStats:action.payload             
            };

        case ProgressActionTypeConst.CHANGE_BACKEND_ALL_USERS:
            return {
                ...state,
                backendAllUsers:action.payload             
            };
    
        case ProgressActionTypeConst.CHANGE_LOAD_ALL_DATA:
            return {
                ...action.payload, progressBackend:0         
            };

        case ProgressActionTypeConst.CHANGE_PROGRESS_SAVE_LOAD_BACKEND:
            return {
                ...state,
                progressBackend:action.payload             
            };
            

        default:
            return state;
    }
};

export default Progress;
