export enum FormAnalysisActionTypeConst {
    CHANGE_DATASELECTION_EXPERIMENT = '@@FormAnalysis/CHANGE_DATASELECTION_EXPERIMENT',
    CHANGE_DATASELECTION_SAMPLES = '@@FormAnalysis/CHANGE_DATASELECTION_SAMPLES',
    CHANGE_DATASELECTION_SELECTION_EXPERIMENT = '@@FormAnalysis/CHANGE_DATASELECTION_SELECTION_EXPERIMENT',
    
    CHANGE_FILTER1_MIN_CELL_PER_GENE = '@@FormAnalysis/CHANGE_FILTER1_MIN_CELL_PER_GENE',
    CHANGE_FILTER1_MIN_GENE_PER_CELL = '@@FormAnalysis/CHANGE_FILTER1_MIN_GENE_PER_CELLE',
    CHANGE_FILTER2_DIFF_GENES_PER_CELL_MIN= '@@FormAnalysis/CHANGE_FILTER2_DIFF_GENES_PER_CELL_MIN',
    CHANGE_FILTER2_DIFF_GENES_PER_CELL_MAX= '@@FormAnalysis/CHANGE_FILTER2_DIFF_GENES_PER_CELL_MAX',
    CHANGE_FILTER2_MAX_MITO_PERCENT= '@@FormAnalysis/CHANGE_FILTER2_MAX_MITO_PERCENT',

    CHANGE_QC_FILTERS= '@@FormAnalysis/CHANGE_QC_FILTERS',
    CHANGE_QC_FILTERS_DEFAULT= '@@FormAnalysis/CHANGE_QC_FILTERS_DEFAULT',
    CHANGE_QC_FILTERS_KEY= '@@FormAnalysis/CHANGE_QC_FILTERS_KEY',

    CHANGE_GENES_NUMBER= '@@FormAnalysis/CHANGE_GENES_NUMBER',
    CHANGE_INTEGRATION_MERGE_ENABLE = '@@FormAnalysis/CHANGE_INTEGRATION_MERGE_ENABLE',
    CHANGE_INTEGRATION_MERGE_METADATA = '@@FormAnalysis/CHANGE_INTEGRATION_MERGE_METADATA',

    CHANGE_CLUSTERING_PROJECTION= '@@FormAnalysis/CHANGE_CLUSTERING_PROJECTION',
    CHANGE_CLUSTERING_PROJECTION_LOUVAIN_RESO= '@@FormAnalysis/CHANGE_CLUSTERING_PROJECTION_LOUVAIN_RESO',
    CHANGE_CLUSTERING_PROJECTION_PC_NUMBER= '@@FormAnalysis/CHANGE_CLUSTERING_PROJECTION_PC_NUMBER',
    CHANGE_CLUSTERING_TSNE_PERPLEXITY= '@@FormAnalysis/CHANGE_CLUSTERING_TSNE_PERPLEXITY',
    CHANGE_CLUSTERING_TSNE_EARLY_EXAGGERATION= '@@FormAnalysis/CHANGE_CLUSTERING_TSNE_EARLY_EXAGGERATION',

    CHANGE_CLUSTERING_LAST_SENT= '@@FormAnalysis/CHANGE_CLUSTERING_LAST_SENT',

    CHANGE_SUBCLUSTERING = '@@FormAnalysis/CHANGE_SUBCLUSTERING',
    CHANGE_SUBCLUSTERING_SENT = '@@FormAnalysis/CHANGE_SUBCLUSTERING_SENT',

    CHANGE_GENEEXPRESSION_GENES_NAME_LIST= '@@FormAnalysis/CHANGE_GENEEXPRESSION_GENES_NAME_LIST',
    CHANGE_GENEEXPRESSION_RAW_COUNTS= '@@FormAnalysis/CHANGE_GENEEXPRESSION_RAW_COUNTS',
    CHANGE_GENEEXPRESSION_SORTED_SCATTER= '@@FormAnalysis/CHANGE_GENEEXPRESSION_SORTED_SCATTER',
    CHANGE_GENEEXPRESSION_SCATTER_COLOR= '@@FormAnalysis/CHANGE_GENEEXPRESSION_SCATTER_COLOR',
    CHANGE_GENEEXPRESSION_SCATTER_MARKER_SIZE= '@@FormAnalysis/CHANGE_GENEEXPRESSION_SCATTER_MARKER_SIZE',

    CHANGE_DIFFGENEEXPRESSION_METADATAS= '@@FormAnalysis/CHANGE_DIFFGENEEXPRESSION_METADATAS',
    CHANGE_DIFFGENEEXPRESSION_METADATAS_VS= '@@FormAnalysis/CHANGE_DIFFGENEEXPRESSION_METADATAS_VS',
    CHANGE_DIFFGENEEXPRESSION_CLUSTERS= '@@FormAnalysis/CHANGE_DIFFGENEEXPRESSION_CLUSTERS',

    CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS= '@@FormAnalysis/CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS',
    CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS_VS= '@@FormAnalysis/CHANGE_DIFFGENEEXPRESSION_CLUSTERS_CLUSTERS_VS',
    CHANGE_DIFFGENEEXPRESSION_TYPE= '@@FormAnalysis/CHANGE_DIFFGENEEXPRESSION_TYPE',

    CHANGE_CLEAR_DIFF_GENES_EXPRESSION_2= '@@FormAnalysis/CHANGE_CLEAR_DIFF_GENES_EXPRESSION_2',
    CHANGE_CLEAR_SUB_CLUSTER= '@@FormAnalysis/CHANGE_CLEAR_SUB_CLUSTER',
    CHANGE_CLEAR_ALL= '@@FormAnalysis/CHANGE_CLEAR_ALL',

    CHANGE_LOAD_ALL_DATA= '@@FormAnalysis/CHANGE_LOAD_ALL_DATA',
    CHANGE_USER_SAVES_DATA= '@@FormAnalysis/CHANGE_USER_SAVES_DATA',
    CHANGE_USER_DATA_MEMORY_USAGE= '@@FormAnalysis/CHANGE_USER_DATA_MEMORY_USAGE',
    CHANGE_USER_DATA_SAVED_ANALYSIS_MEMORY_USAGE= '@@FormAnalysis/CHANGE_USER_DATA_SAVED_ANALYSIS_MEMORY_USAGE',
    CHANGE_USER_RIGHTS= '@@FormAnalysis/CHANGE_USER_RIGHTS',
    CHANGE_WEBSERVER_ADMIN_INFOS= '@@FormAnalysis/CHANGE_WEBSERVER_ADMIN_INFOS',
}
