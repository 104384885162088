import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

// helpers
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    changePassword as changePasswordApi
} from '../../helpers/';

// actions
import { authApiResponseSuccess, authApiResponseError, changeValidationKeyStatus } from './actions';

// constants
import { AuthActionTypes } from './constants';

interface UserData {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
        validation_key: string;
        //password_new : string;
        //password_old : string;
    };
    type: string;
}

type AnyAction = {type: string, [key: string]: any}

interface UserPassword {
    payload: {
        password_old: string;
        password_new: string;
    };
    type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password }, type }: UserData): SagaIterator {


    try {
        const response = yield call(loginApi, { email, password });

        let user = {}
        console.log("response:",response)

        if ( response.data.includes("error") )
        {
            if (response.data.includes("error_password") )
            {
                console.log("error_password")
    
                api.setLoggedInUser(null);
                setAuthorization(null);
                yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "password incorrect"));
            }
            else if (response.data.includes("error_email") )
            {
                console.log("error_email")
    
                api.setLoggedInUser(null);
                setAuthorization(null);
                yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "Unknown e-mail address"));
            }
            else if (response.data.includes("error_account_validation") )
            {
                console.log("error_account_validation")
    
                api.setLoggedInUser(null);
                setAuthorization(null);
                changeValidationKeyStatus(true)
                yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "The account is not validated, go in the sign up panel to validate your account"));
            }
            else if (response.data.includes("") )
            {
                console.log("Unknown error")
    
                api.setLoggedInUser(null);
                setAuthorization(null);
                yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "Unknown error"));
            }
        }
        else if ( response.data.length > 1)
        {
            let res_token = response.data
            console.log("Successfully login")

            user = 
            {
                id: 1,
                username: email.split('@')[0],
                password: 'pass',
                fullname: 'Test',
                email: email,
                firstName: 'Test',
                lastName: 'User',
                role: 'Admin',
                token_server: res_token,
                token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
            }

            console.log("auth/saga user:", user)
            api.setLoggedInUser(user);
            setAuthorization(res_token);

           // sendMessage()

            //const test = WebsocketComputation()
            //test.websocket_computation.send("aaa")

            yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
        }
        else
        {
            console.log("Unknown error ...")

            api.setLoggedInUser(null);
            setAuthorization(null);
            yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "Unknown error ..."));
        }
        // const user = response.data;
        // NOTE - You can change this according to response format from your api

        
        
    } catch (error: any) {
        console.log("login saga catch ",error)
        
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "Login failed, service error"));
        api.setLoggedInUser(null);
        setAuthorization(null);
        
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        //yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { email, password, validation_key } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { email, password, validation_key });
        console.log("signup response=", response)

        let user = {}

        if (response.data.length < 0 || response.data.includes("error"))
        {
            console.log("response.data: ", response.data)

            
            if (response.data.includes("error_account_notValid_email_exists"))
            {
                console.log("error_account_notValid_email_exists !")
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "error_account_notValid_email_exists"));
            }
            else if (response.data.includes("error_email_exists"))
            {
                console.log("error_email_exists !")
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "already used adress"));
            }
            else if (response.data.includes("error_password_length"))
            {
                console.log("error_password_length !")
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "password must constains at least 6 characters"));
            }
            else if (response.data.includes("error_email_length"))
            {
                console.log("error_email_length !")
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "email : not enough charcarters"));
            }
            else if (response.data.includes("error_sql_request"))
            {
                console.log("error_sql_request !")
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "error during signup processs"));
            }
            else if (response.data.includes("error_validation_key") )
            {
                console.log("error_validation_key")
    
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "The validation key does not match with the key sent by e-mail"));
            }
            else
            {
                yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "unknown error"));
            }
        }
        else if (response.data.includes("ask_validation_key"))
        {
            console.log("call changeValidationKeyStatus")
            //dispatch(changeValidationKeyStatus(true));
            yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, ""));
            yield put(changeValidationKeyStatus(true));
        }
        else if (response.data.includes("token:"))
        {
            let res_token = response.data.replace("token:", "")
            console.log("Successfully signup res_token:", res_token, " email:",email," username:",email.split('@')[0])
           

            user = 
            {
                id: 1,
                username: email.split('@')[0],
                password: 'pass',
                fullname: 'Test',
                email: email,
                firstName: 'Test',
                lastName: 'User',
                role: 'Admin',
                token_server: res_token,
                token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
            }

            yield put(changeValidationKeyStatus(false));
            yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
            //api.setLoggedInUser(user);
            //setAuthorization(res_token); 
        }
        else
        {
            console.log("sage unknown error ")
            yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "unknown error"));
        }
        
        // const user = response.data;



        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        
    } catch (error: any) {
        console.log("sage catch error ", error)
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, "service error"));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
    console.log("forgotPassword")
    
    try {
        const response = yield call(forgotPasswordApi, { email });
        console.log("forgotPassword response:", response)

        if (response.data.length < 0 || response.data.includes("error"))
        {
            console.log("forgotPassword err")
            yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, "error"));
        }
        else
        {
            console.log("forgotPassword succ")
            yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, {message:response.data} ));//response.data));
        }
        
    } catch (error: any) {
        console.log("forgotPassword err")
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* changePassword({ payload: { password_old, password_new, user_token_server } }: AnyAction): SagaIterator {
    console.log("changePassword")
    
    
    try {
        const response = yield call(changePasswordApi, { password_old, password_new, user_token_server });
        console.log("changePassword response:", response)

        if (response.data.length < 0 || response.data.includes("error"))
        {
            console.log("changePassword err")
            yield put(authApiResponseError(AuthActionTypes.CHANGE_PASSWORD, response.data));
        }
        else
        {
            console.log("changePassword succ")
            yield put(authApiResponseSuccess(AuthActionTypes.CHANGE_PASSWORD, {message:response.data} ));//response.data));
        }
        
    } catch (error: any) {
        console.log("changePassword err")
        yield put(authApiResponseError(AuthActionTypes.CHANGE_PASSWORD, error));
    }
    
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchChangePassword(): any {
    yield takeEvery(AuthActionTypes.CHANGE_PASSWORD, changePassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword), fork(watchChangePassword)]);
}

export default authSaga;
