import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Websocket from './websocket/reducers';
import formAnalysis from './formAnalysis/reducers';
import Params from './params/reducers';
import Progress from './progress/reducers';

export default combineReducers({
    Auth,
    Layout,
    Websocket,
    formAnalysis,
    Params,
    Progress
});
